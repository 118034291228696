<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
                    <span><i
                        class="fa fa-user"></i> {{ currentItem ? 'Редактирование раздела' : 'Добавление раздела' }}</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="type" rules="required" v-slot="{invalid, validated}">
                <v-select
                    v-model="type"
                    :items="types"
                    item-value="id"
                    item-text="name"
                    label="Тип *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                ></v-select>
              </ValidationProvider>
              <ValidationProvider name="name" rules="required" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="Название *"
                    hide-details="auto"
                    v-model="name"
                ></v-text-field>
              </ValidationProvider>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "product-section-create-update-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  data() {
    let state = {
      type: '',
      types: [{id: 'dealer', name: 'Дилер'}, {id: 'subdivision', name: 'Структурное подразделение'}],
      name: ''
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    createEntity: api.catalog.createSection,
    updateEntity: api.catalog.updateSection,
    closeDialog() {
      this.$emit('close');
    },
  },
  computed: {
    data() {
      return {
        name: this.name,
        type: this.type
      }
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":false,"width":"600"},on:{"input":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_c('i',{staticClass:"fa fa-user"}),_vm._v(" "+_vm._s(_vm.currentItem ? 'Редактирование раздела' : 'Добавление раздела'))])]),_c('v-card-text',[_c('form',[_c('v-layout',{attrs:{"column":""}},[_c('ValidationProvider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-select',{attrs:{"items":_vm.types,"item-value":"id","item-text":"name","label":"Тип *","hide-details":"auto","required":"","error":invalid && validated},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-text-field',{attrs:{"error":invalid && validated,"label":"Название *","hide-details":"auto"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('i',{staticClass:"fa fa-save mr-2"}),_c('span',[_vm._v("Сохранить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
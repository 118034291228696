<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs"> </breadcrumbs>
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center">
                <CButton
                    @click="
                        clearCurrentItem()
                        showEntityModal = true
                    "
                    class="add-product-modal"
                    color="info"
                    ><i class="la la-plus"></i>
                    Добавить раздел
                </CButton>
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="sections"
                    :pageable="gridPageable"
                    :filterable="true"
                    :sortable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="[
                                        {
                                            text: 'Редактировать',
                                            icon: 'la la-pencil',
                                            handler: () => {
                                                setCurrentItem(dataItem)
                                                showEntityModal = true
                                            },
                                        },
                                        {
                                            text: 'Удалить',
                                            icon: 'la la-trash',
                                            handler: () =>
                                                deleteEntity(dataItem),
                                        },
                                    ]"
                                />
                            </div>
                        </td>
                    </template>
                    <template v-slot:type="{ props: { dataItem } }">
                        <td>
                            {{
                                dataItem.type === 'dealer'
                                    ? 'Дилер'
                                    : 'Структурное подразделение'
                            }}
                        </td> </template
                    >,
                    <template v-slot:typeFilter="{ methods, props }">
                        <dropdownlist
                            class="categories-filter"
                            :data-items="types"
                            :text-field="'name'"
                            :data-item-key="'id'"
                            :default-item="{ id: null, name: 'Все' }"
                            @change="selectType"
                        >
                        </dropdownlist>
                    </template>
                </Grid>
            </intl>
        </localization>
        <section-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></section-modal>
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import { DropDownList } from '@progress/kendo-vue-dropdowns'
    import { mapGetters } from 'vuex'
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
    import { showConfirm } from '@/lib/swal2'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import SectionCreateUpdateModal from '../modals/ProductSectionCreateUpdateModal'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { showMessage } from '@/lib/toasted'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'sections',
        components: {
            Grid: Grid,
            dropdownlist: DropDownList,
            ActionsDropdown,
            breadcrumbs: Breadcrumbs,
            'section-modal': SectionCreateUpdateModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
        },
        mixins: [TableMixin, ResizableTableMixin],
        mounted() {
            this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 0,
                gridPageable: { pageSizes: true },
                expandedItems: [],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'name',
                        title: 'Название',
                    },
                    {
                        field: 'categories_count',
                        title: 'Количество категорий товаров',
                    },
                    {
                        field: 'type',
                        title: 'Тип',
                        cell: 'type',
                        filterCell: 'typeFilter',
                        filter: 'text',
                        minWidth: 200,
                        width: '200px',
                    },
                ],
                types: [
                    { id: 'dealer', name: 'Дилер' },
                    { id: 'subdivision', name: 'СП' },
                ],
            }
        },
        methods: {
            fetchData: api.catalog.sections,
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный раздел?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.catalog.deleteSection(item.id)
                    showMessage(status, message)

                    this.updateTable()
                }
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            selectType({ value }) {
                if (value.id) {
                    this.setFilter('type', 'eq', value.id)
                } else {
                    this.removeFilter('type')
                }

                this.updateTable(true)
            },
        },
    }
</script>

<style scoped></style>
